import styled from 'styled-components';

export const Main = styled.main`
  h1 {
    text-align: center;
    margin-bottom: 8px;

    span:nth-child(1) {
      color: #008941;
    }
    span:nth-child(2) {
      color: #ededed;
    }
    span:nth-child(3) {
      color: #d93442;
    }
  }

  h2 {
    text-align: center;
    font-size: 1rem;
    margin-top: 0;
    margin-bottom: 30px;
  }
`;

export const Menu = styled.div`
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;

  .menu-title {
    font-size: 1.75rem;
    margin: 25px auto 35px;
  }

  .menu-obs {
    p {
      font-size: 0.875rem;
      color: #cbcdcc;
      margin: 15px 15px 35px;
    }
  }

  .menu-obs2 {
    h2 {
      font-family: 'Roboto Slab', serif;
      text-align: center;
    }

    p {
      text-align: center;
      font-size: 0.875rem;
      color: #00d664;
      margin: 15px 15px 35px;
    }
  }

  @media (min-width: 768px) {
    padding: 30px;
  }

  .item-sizes {
    display: flex;
    justify-content: flex-end;
    text-align: center;
    font-weight: 600;
    text-transform: uppercase;

    > div {
      padding: 2px;
      margin: 0 1px 10px;
      width: 55px;
      height: 40px;
      font-size: 0.875rem;
      position: relative;
    }

    > div:nth-child(1) {
      background: #008941;
    }

    > div:nth-child(2) {
      background: #ededed;
      color: rgb(42, 44, 43);
    }

    > div:nth-child(3) {
      background: #d93442;
    }
  }

  .custom-shape-divider-bottom-1605973662 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
  }

  .custom-shape-divider-bottom-1605973662 svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 16px;
  }

  .custom-shape-divider-bottom-1605973662 .shape-fill {
    fill: rgb(42, 44, 43);
  }
`;

export const Item = styled.div`
  margin-bottom: 45px;
  color: #fafafa;

  .item-separator {
    border-bottom: 1px dotted rgba(255, 255, 255, 0.25);
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5px;
  }

  .item-title {
    flex-grow: 1;
    /* text-transform: uppercase; */
    font-weight: 500;

    span {
      background-color: #00d664;
      color: #2a2c2b;
      padding: 2px 3px;
      font-size: 0.875rem;
      border-radius: 3px;
    }
  }

  .item-price-wrapper {
    display: flex;
    text-align: center;
  }

  .item-price {
    width: 55px;
    margin-left: 1px;
    margin-right: 1px;
    font-weight: 500;

    span {
      font-size: 0.75rem;
    }
  }

  .item-desc {
    font-size: 0.875rem;
    color: #cbcdcc;
    font-weight: 400;
    list-style: none;
    margin-left: 0;
  }
`;
