import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';

const Container = styled.section`
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 20px;
`;

const StyledButton = styled(Button)`
  background-color: #444444;
  color: #b5ad60;
  border-radius: 6px;
  padding: 8px 18px;
  margin: 6px;

  &:hover,
  &:active {
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #e2dc54;
    color: #242424;
  }
`;

const Nav = () => {
  function isActive({ isCurrent }) {
    return isCurrent ? { className: 'active' } : null;
  }

  return (
    <Container>
      <StyledButton
        variant="contained"
        disableElevation
        component={Link}
        to="/"
        activeClassName="activeNav"
        getProps={isActive}
      >
        Pizzas
      </StyledButton>

      <StyledButton
        variant="contained"
        disableElevation
        component={Link}
        to="/esfihas"
        activeClassName="activeNav"
        getProps={isActive}
      >
        Esfihas
      </StyledButton>
    </Container>
  );
};

export default Nav;
