import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Clock } from 'styled-icons/fa-regular/Clock';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import { Map } from 'styled-icons/boxicons-regular/Map';
import { Facebook } from 'styled-icons/boxicons-logos/Facebook';
import { Instagram } from 'styled-icons/boxicons-logos/Instagram';

import italyflag from '../images/italy-flag.png';
import left from '../images/left.png';
import right from '../images/right.png';

const HeaderWrapper = styled.header`
  text-align: center;
  font-size: 0.875rem;
  padding: 15px;
  position: relative;

  background-image: url("${italyflag}"), url("${left}"), url("${right}");
  background-position: top, left, right;
  background-repeat: no-repeat;
  background-size: 200px, 150px, 150px;

  p {
    text-shadow: 0px 4px 3px rgba(0,0,0,0.4),
             0px 8px 13px rgba(0,0,0,0.1),
             0px 18px 23px rgba(0,0,0,0.1);
  }

  @media (min-width: 650px) {
    background-size: auto;
  }

  @media (min-width: 830px) {
    margin: 30px auto 0;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }
`;

const Social = styled.div`
  padding: 9px 0;
  a {
    padding: 0 5px;
  }
`;

const Phones = styled.p`
  font-size: 1.25rem;
  font-weight: 600;

  span {
    font-size: 0.875rem;
    font-weight: normal;
  }

  .consulte {
    white-space: nowrap;
  }

  @media (max-width: 425px) {
    margin-bottom: 5px;
  }
`;

const ImageWrapper = styled.div`
  /* margin-top: 70px; */

  .gatsby-image-wrapper {
    max-width: 250px;
    margin: 15px auto;
  }

  @media (max-width: 425px) {
    .gatsby-image-wrapper {
      margin: 10px auto;
    }
  }
`;

const Hours = styled.p`
  margin-top: 25px;

  @media (max-width: 425px) {
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;

const Address = styled.p`
  margin-bottom: 5px;
`;

const IconWhatsapp = styled(Whatsapp)`
  margin: 0 2px 3px 2px;
  color: #25d366;
`;

const IconFacebook = styled(Facebook)`
  background: #3b5998;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconInstagram = styled(Instagram)`
  background: #d6249f;
  background: radial-gradient(
    circle at 30% 107%,
    #fdf497 0%,
    #fdf497 5%,
    #fd5949 45%,
    #d6249f 60%,
    #285aeb 90%
  );
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const IconMap = styled(Map)`
  background: #dd4b39;
  color: #ffffff;
  padding: 3px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
`;

const Header = ({ siteTitle }) => (
  <StaticQuery
    query={graphql`
      query {
        logo: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 250, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `}
    render={data => (
      <HeaderWrapper>
        <Social>
          <a
            href="https://www.facebook.com/Castellideliveryiracema"
            aria-label="Visitar página no Facebook"
          >
            <IconFacebook size={23} />
          </a>

          <a
            href="https://www.instagram.com/castelliiracemapolis/"
            aria-label="Visitar página no Instagram"
          >
            <IconInstagram size={23} />
          </a>

          <a
            href="https://goo.gl/maps/RCQfCPGiUffDG2tp7"
            aria-label="Visitar local no Google Maps"
          >
            <IconMap size={23} />
          </a>
        </Social>

        <ImageWrapper>
          <Img
            fluid={data.logo.childImageSharp.fluid}
            alt={`Logo ${siteTitle}`}
          />
        </ImageWrapper>

        <Phones>
          <small>19</small> 99915.8822
          <IconWhatsapp size={30} />
        </Phones>

        <Hours>
          <Clock size={15} /> Terça a Domingo das 18 às 23h
        </Hours>

        <Address>
          <Map size={23} /> R. Antônio Joaquim Fagundes, 312 - Centro
        </Address>
      </HeaderWrapper>
    )}
  />
);

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
