import React from 'react';
import styled from 'styled-components';
import { Whatsapp } from 'styled-icons/boxicons-logos/Whatsapp';
import Button from '@material-ui/core/Button';

const StyledButton = styled(Button)`
  position: fixed;
  bottom: 30px;
  right: 30px;
  background-color: #25d366;
  color: rgb(42, 44, 43);
  text-align: center;
  font-size: 0.75rem;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  z-index: 1200;
  &:hover {
    background-color: #25d366;
    color: rgb(42, 44, 43);
  }
`;

const WhatsappBtn = () => (
  <StyledButton
    variant="contained"
    color="primary"
    href="https://api.whatsapp.com/send?phone=5519999158822"
    startIcon={<Whatsapp size={32} />}
    aria-label="Click aqui para fazer pedido via whatsapp"
  >
    Fazer <br />
    Pedido
  </StyledButton>
);

export default WhatsappBtn;
