import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import styled, { createGlobalStyle } from 'styled-components';

import Header from './header';
import WhatsappBtn from './whatsappBtn';
import BackToTop from './backToTop';

const GlobalStyle = createGlobalStyle`
  html {
    scroll-behavior: smooth;
  }
  
  body {
    background-color: #ccc;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .active {
    border-radius: 6px;
    padding: 12px 18px;
    margin: 6px;
    box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
    background-color: #e2dc54;
    color: #444444;
    text-transform: uppercase;
    font-size: 0.875rem;
    font-weight: bold;
  }
`;

const Container = styled.div`
  background: rgb(42, 44, 43);
  background: radial-gradient(
    circle,
    rgba(42, 44, 43, 1) 90%,
    rgba(24, 25, 24, 1) 100%
  );
  max-width: 800px;
  margin: 0 auto;
  box-shadow: 10px 10px 10px -7px rgba(0, 0, 0, 0.51);

  @media (min-width: 830px) {
    margin: 30px auto;
    border-radius: 6px;
  }
`;

const Footer = styled.footer`
  padding: 30px 15px;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.875rem;
  color: #8c8b8b;

  a {
    text-decoration: underline;
  }

  hr {
    display: block;
    border-top: 3px double #8c8b8b;
  }
`;

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <GlobalStyle />

        <Container>
          <Header siteTitle={data.site.siteMetadata.title} />

          {children}

          <BackToTop />

          <Footer>
            <hr />
            <p>
              © {new Date().getFullYear()} - {data.site.siteMetadata.title}
            </p>

            <p>
              Cardápio desenvolvido por{' '}
              <a href="https://mznapplab.com">MZN App Lab</a>
            </p>
          </Footer>
        </Container>

        <WhatsappBtn />
      </>
    )}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
